@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Digital-7';
    src: url('./fonts/digital-7.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
/* Base style for Beta events - Red */
.beta-events {
  background-color: #f87171 !important; /* Tailwind red-400 */
  border-color: #f87171 !important;
  color: white !important; /* White text for readability */
}

/* Hover state for Beta events */
.beta-events:hover {
  background-color: #fca5a5 !important; /* Lighter red */
  border-color: #fca5a5 !important;
  color: white !important;
}

/* Base style for Flow events - Green */
.flow-events {
  background-color: #34d399 !important; /* Tailwind green-400 */
  border-color: #34d399 !important;
  color: white !important;
}

/* Hover state for Flow events */
.flow-events:hover {
  background-color: #6ee7b7 !important; /* Lighter green */
  border-color: #6ee7b7 !important;
  color: white !important;
}

/* Base style for Pet Jokic's Horses - Blue */
.pet-events {
  background-color: #93c5fd !important; /* Tailwind blue-300 */
  border-color: #93c5fd !important;
  color: white !important;
}

.pet-events:hover {
  background-color: #bfdbfe !important; /* Lighter blue */
  border-color: #bfdbfe !important;
  color: white !important;
}

/* Base style for Flow Community events - Blue */
.flow-community-events {
  background-color: #60a5fa !important; /* Tailwind blue-400 */
  border-color: #60a5fa !important;
  color: white !important;
}

.flow-community-events:hover {
  background-color: #93c5fd !important; /* Lighter blue */
  border-color: #93c5fd !important;
  color: white !important;
}

/* Event label for the source */
.event-label {
  font-size: 0.75rem; /* Small text for the label */
  color: white; /* Label color */
  opacity: 0.75; /* Slight transparency */
  margin-bottom: 0.25rem;
}

/* Modal container */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content */
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 100%;
}

.modal-content h2 {
  margin: 0 0 10px;
}

.modal-content p {
  margin: 0 0 10px;
}

.modal-content a {
  color: #1d4ed8; /* Tailwind blue-700 */
  text-decoration: underline;
}

.modal-content button {
  padding: 10px 15px;
  background-color: #34d399; /* Tailwind green-400 */
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.modal-content button:hover {
  background-color: #059669; /* Tailwind green-600 */
}
