@font-face {
  font-family: 'Microgramma';
  src: url('../fonts/Microgramma Normal.ttf') format('truetype');
}
@font-face {
  font-family: 'Digital-7';
  src: url('../fonts/digital-7.ttf') format('truetype');
}

.time-circuits-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4px;
  width: 100%;
  max-width: 44rem;
  padding: 0 20px;
  overflow: hidden;
}

.time-circuits-background {
  width: 100%;
  padding: 8px 20px;
  text-align: center;
  background-color: #5c5c5c; /* Dark grey base */
  background-image: 
    radial-gradient(circle, rgba(255, 255, 255, 0.05) 1px, transparent 1px) 10% 20%,
    radial-gradient(circle, rgba(0, 0, 0, 0.2) 2px, transparent 2px) 40% 30%,
    radial-gradient(circle, rgba(255, 255, 255, 0.03) 2px, transparent 2px) 70% 80%,
    radial-gradient(circle, rgba(0, 0, 0, 0.15) 1px, transparent 1px) 20% 60%,
    radial-gradient(circle, rgba(255, 255, 255, 0.1) 1px, transparent 1px) 80% 40%;
  background-size: 100% 100%, 100% 100%, 100% 100%, 100% 100%, 100% 100%;
  background-blend-mode: overlay;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  position: relative;
  height: auto;
  padding: 8px 20px;
}

.time-circuits-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.3), transparent);
  opacity: 0.2;
  pointer-events: none;
  mix-blend-mode: multiply;
}

.non-clickable {
  pointer-events: none;
  cursor: default;
}

.labels-container {
  display: flex;
  justify-content: space-between;
  color: white;
  margin-bottom: 8px; /* Increased margin-bottom to create a gap */
}

.label-wrapper {
  text-align: center;
  margin-top: 5px;
}

.label {
  display: block;
  background-color: #8e1a0a;
  color: white;
  padding: 2px 3px;
  font-family: 'Microgramma', sans-serif;
  font-size: 0.75em;
  width: fit-content;
}

.digital-number {
  position: relative;
  font-family: 'Digital-7', sans-serif;
  font-size: 4.5rem;
  line-height: 1;
  color: #ff6913;
  background-color: black;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  margin: 0 0.1rem;
  border-radius: 0.2rem;
  margin-top: 8px;
  text-align: right; /* Ensure text is right-aligned */
}

.digital-number span {
  position: relative;
  display: inline-block;
  z-index: 1;
  min-width: 1ch; /* Ensure consistent width for each digit */
  text-align: right; /* Right-align the text within the span */
}

.digital-number span::before {
  content: '8';
  position: absolute;
  top: -0.1rem;
  left: 0;
  width: 100%;
  height: 100%;
  color: rgba(255, 0, 0, 0.4);
  z-index: 0;
  pointer-events: none;
}

.digital-number span.digit-1 {
  min-width: 1ch;
  text-align: right; /* Right-align the "1" digit */
}


@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.blinking-colon .dot {
  width: 12px;
  height: 12px;
  background-color: #fed24a;
  border-radius: 50%;
  margin-bottom: 4px;
}

.blinking-colon {
  animation: blink 1s infinite;
  position: absolute;
  top: 50%;
  left: -16px;
  transform: translateY(-50%);
}

.am-pm-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-left: -15px;  /* Move closer to the YEAR section */
  margin-right: -15px; /* Move closer to the HOUR section */
}

.am-pm-label {
  background-color: #8e1a0a;
  color: white;
  padding: 2px 3px;
  font-family: 'Microgramma', sans-serif;
  font-size: 0.7em;
  margin-bottom: 2px;
}

.am-pm-light {
  width: 12px;
  height: 12px;
  background-color: #fed24a;
  border-radius: 50%;
  margin-bottom: 8px;
}

.am-pm-light.off {
  background-color: #555555; /* PM light color (inactive) */
}

.destination-time {
  color: white;
  font-size: 1.25rem;
  margin-top: 8px;
  padding: 2px 8px;
  background-color: black;
  font-family: 'Microgramma', sans-serif;
  display: inline-block;
  width: auto;
}

.text-center.relative {
  margin-left: -10px; /* Move the HOUR section closer to the AM/PM section */
}

.text-center.relative:last-child {
  margin-left: -5px; /* Reduce spacing for MIN section */
  padding-right: 10px;
}

.blinking-lights-vertical {
  display: flex;
  flex-direction: column; /* Stack the lights vertically */
  align-items: center;
  justify-content: center;
  margin: 30px 5px 0 5px; /* Space between the lights and the adjacent sections */
}

.blinking-light {
  width: 12px;
  height: 12px;
  background-color: #555555; /* Start with grey color (inactive) */
  border-radius: 50%;
  margin: 4px 0; /* Space between the two lights vertically */
  animation: blinkTwice 0.5s infinite alternate; /* Blink every 0.5s */
}

@keyframes blinkTwice {
  0% {
    background-color: #555555; /* Grey color (inactive) */
  }
  100% {
    background-color: #fed24a; /* Yellow color (active) */
  }
}

.go-88mph-button {
  position: relative;
  margin-left: 20px;
  background-color: #ff4500;
  color: white;
  font-family: 'Microgramma', sans-serif;
  font-size: 1.2rem;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.go-88mph-button:hover {
  background-color: #ff6347;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .time-circuits-container {
    display: none !important;
  }
}









